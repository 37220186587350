import { Product } from "../context/SearchContext";
import { Source } from "./PipelineService.ts";
import { BrowserInfo, getBrowserInfo } from "../utils/browserInfo";
import { logException } from "../utils/logger";
import { retry } from "../utils/retryFunction";
export interface AnalyticsPayload {
  action: string;
  requestId?: string;
  contextId: string;
  query?: string;
  description: string;
  datetime: string;
  textResponse?: string;
  sources?: Source[];
  duration?: number;
  feedbackCategory?: string | null;
  selectedOptions?: string[];
  comment?: string;
  href?: string;
  hrefText?: string;
  browserInfo?: BrowserInfo;
  products?: Product[];
  suggestedQueries?: string[];
  agentId?: string;
  feedbackRelevant?: boolean;
}

export const submitAnalytics = async (payload: AnalyticsPayload) => {
  const useZapierAnalytics =
    import.meta.env.VITE_USE_ZAPIER_ANALYTICS?.toLowerCase() === "true";

  const fullPayload: AnalyticsPayload = {
    action: payload.action,
    requestId: payload.requestId ?? "",
    contextId: payload.contextId,
    query: payload.query ?? "",
    description: payload.description,
    datetime: payload.datetime || new Date().toISOString(),
    textResponse: payload.textResponse ?? "",
    sources: payload.sources ?? [],
    duration: payload.duration ?? 0,
    feedbackCategory: payload.feedbackCategory ?? "",
    selectedOptions: payload.selectedOptions ?? [],
    comment: payload.comment ?? "",
    href: payload.href ?? "",
    hrefText: payload.hrefText ?? "",
    browserInfo: getBrowserInfo(),
    products: payload.products ?? [],
    suggestedQueries: payload.suggestedQueries ?? [],
    agentId: payload.agentId ?? "",
    feedbackRelevant: payload.feedbackRelevant ?? false,
  };

  const url = `${import.meta.env.VITE_SEARCH_SERVICE_URL}/analytics`;

  const mainAnalyticsFunction = async () => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
      },
      body: JSON.stringify(fullPayload),
    });

    if (!response.ok) {
      const errorMesssage = `Failed to send analytics data to db: ${response.status} ${response.statusText}`;
      logException(errorMesssage);
      throw new Error(errorMesssage);
    }
  };

  const onMaxRetriesReached = () => {
    if (useZapierAnalytics) {
      const errorMesssage = "Max retries reached. Sending data to Zapier.";
      console.error(errorMesssage);
      submitAnalyticsZapier(payload);
    }
  };

  await retry(mainAnalyticsFunction, onMaxRetriesReached, 5, 250);
};

export const submitAnalyticsZapier = async (payload: AnalyticsPayload) => {
  const fullPayload: AnalyticsPayload = {
    action: payload.action,
    requestId: payload.requestId ?? "",
    contextId: payload.contextId,
    query: payload.query ?? "",
    description: payload.description,
    datetime: payload.datetime || new Date().toISOString(),
    textResponse: payload.textResponse ?? "",
    sources: payload.sources ?? [],
    duration: payload.duration ?? 0,
    feedbackCategory: payload.feedbackCategory ?? "",
    feedbackRelevant: payload.feedbackRelevant ?? false,
    selectedOptions: payload.selectedOptions ?? [],
    comment: payload.comment ?? "",
    href: payload.href ?? "",
    hrefText: payload.hrefText ?? "",
    browserInfo: getBrowserInfo(),
    products: payload.products ?? [],
    suggestedQueries: payload.suggestedQueries ?? [],
    agentId: payload.agentId ?? "",
  };

  const webhookURL = import.meta.env.VITE_ZAPIER_ANALYTICS_WEBHOOK_URL;

  if (!webhookURL) {
    return;
  }
  try {
    await fetch(webhookURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(fullPayload),
    });
  } catch (error) {
    const errorMesssage = "Failed to send analytics data to Zapier: " + error;
    logException(errorMesssage);
  }
};
