import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  FormEvent,
  useMemo,
} from "react";
import { InputText } from "primereact/inputtext";
import logoAIFindr from "../../assets/Logo-AIFindr.svg";
import { useSearch } from "../../context/SearchContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "../../index.css";
import WaveAnimation from "../atoms/WaveAnimation";
import MicrophoneButton from "../atoms/MicrophoneButton";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SpeechRecognition: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkitSpeechRecognition: any;
  }
}

interface SearchBarProps {
  isInternalSearch: boolean;
  isLoading?: boolean;
  setFeedbackSent?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  isInternalSearch,
  isLoading = false,
  setFeedbackSent,
}) => {
  const { t } = useTranslation();
  const { handleSearch } = useSearch();

  const [search, setSearch] = useState("");
  const [placeholderLabel, setPlaceholderLabel] = useState<string>("");
  const [typedPlaceholder, setTypedPlaceholder] = useState<string>("");
  const [showCursor, setShowCursor] = useState<boolean>(true);

  const [isListening, setIsListening] = useState<boolean>(false);
  const [waveHeights, setWaveHeights] = useState<number[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;

  const recognition = useMemo(() => {
    if (SpeechRecognition) {
      const rec = new SpeechRecognition();
      rec.interimResults = true;
      return rec;
    }
    return null;
  }, [SpeechRecognition]);

  const showFeedback = useCallback(() => {
    if (setFeedbackSent) {
      setFeedbackSent(false);
    }
  }, [setFeedbackSent]);

  const doSearch = useCallback(
    (query: string) => {
      const searchType = isInternalSearch ? "internal" : "initial";
      const finalQuery = query.trim() !== "" ? query.trim() : typedPlaceholder;
      handleSearch(finalQuery, searchType).then();
      setSearch("");
      showFeedback();
    },
    [isInternalSearch, handleSearch, showFeedback, typedPlaceholder],
  );

  useEffect(() => {
    if (!recognition) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recognition.onresult = (event: { results: string | any[] }) => {
      let transcript = "";
      for (let i = 0; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setSearch(transcript);
    };

    recognition.onend = () => {
      setIsListening(false);

      if (search.trim() !== "") {
        doSearch(search);
      }
    };
  }, [recognition, search, doSearch]);

  const toggleListening = useCallback(() => {
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
    }
    if (!recognition) return;
    if (isListening) {
      recognition.stop();
      setIsListening(false);
    } else {
      const newHeights = Array.from({ length: 16 }, () =>
        parseFloat((Math.random() * 0.8 + 0.5).toFixed(2)),
      );
      setWaveHeights(newHeights);

      setSearch("");
      recognition.start();
      setIsListening(true);
    }
  }, [isListening, recognition]);

  useEffect(() => {
    const labels = [
      t("translation.recommended-1", { defaultValue: "Search products..." }),
      t("translation.recommended-2", { defaultValue: "Find what you need..." }),
      t("translation.recommended-3", {
        defaultValue: "Looking for something specific?",
      }),
      t("translation.recommended-4", {
        defaultValue: "Discover new products...",
      }),
      t("translation.recommended-5", {
        defaultValue: "Explore our catalog...",
      }),
    ];

    const getRandomLabel = () => {
      const filteredLabels = labels.filter(
        (label) => label && label.trim().length > 0,
      );
      if (filteredLabels.length === 0) return "Search products...";
      const randomIndex = Math.floor(Math.random() * filteredLabels.length);
      return filteredLabels[randomIndex];
    };

    const updatePlaceholderImmediately = () => {
      const initialLabel = getRandomLabel();
      setPlaceholderLabel(initialLabel);
    };

    if (!isLoading) {
      updatePlaceholderImmediately();

      const interval = setInterval(() => {
        const newLabel = getRandomLabel();
        setPlaceholderLabel(newLabel);
      }, 8000);

      i18n.on("languageChanged", updatePlaceholderImmediately);

      return () => {
        clearInterval(interval);
        i18n.off("languageChanged", updatePlaceholderImmediately);
      };
    }
  }, [isLoading, t]);

  useEffect(() => {
    setTypedPlaceholder("");
    if (!placeholderLabel) return;

    let currentIndex = 0;
    const typingSpeed = 50;

    const typingInterval = setInterval(() => {
      if (currentIndex >= placeholderLabel.length) {
        clearInterval(typingInterval);
        return;
      }
      const nextChar = placeholderLabel[currentIndex] ?? "";
      setTypedPlaceholder((prev) => prev + nextChar);
      currentIndex++;
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [placeholderLabel]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => {
      clearInterval(cursorInterval);
    };
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    doSearch(search);
  };

  return (
    <div
      className="relative space-y-2 transition-all duration-300 ease-in-out"
      ref={wrapperRef}
    >
      <div className="flex justify-center items-center w-full mb-7">
        <div className="w-full justify-center items-center space-y-4">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex space-x-2 p-0 m-0">
              <span className="flex-grow w-full relative">
                <div
                  className="w-full p-[2px] rounded-full overflow-hidden transition-all duration-300"
                  style={{
                    background:
                      "linear-gradient(40deg, #001846 35%, #E8C328 65%)",
                  }}
                >
                  <InputText
                    ref={inputRef}
                    placeholder={
                      isListening
                        ? ""
                        : `${typedPlaceholder}${showCursor ? "|" : ""}`
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={`w-full h-9 pl-10 pr-10 text-REPEOPLE-gray text-md bg-white rounded-full 
                      focus:outline-none transition-all duration-300
                      ${
                        isListening
                          ? "border-2 border-cyan-300 shadow-[0_0_10px_2px_rgba(0,255,242,0.5)]"
                          : ""
                      }
                    `}
                  />

                  {isListening && search.trim().length === 0 && (
                    <WaveAnimation waveHeights={waveHeights} />
                  )}
                </div>

                <span className="absolute inset-y-0 left-3 flex items-center z-20">
                  <img
                    src={logoAIFindr}
                    alt="AIFindr Logo"
                    className="h-5 w-5"
                  />
                </span>

                <span className="absolute inset-y-0 right-3 flex items-center z-20">
                  <MicrophoneButton
                    isListening={isListening}
                    toggleListening={toggleListening}
                  />
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
