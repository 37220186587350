import React, { useEffect, useRef, useState } from "react";
import ResponseContent from "../components/organisms/ResponseContent";
import SearchBar from "../components/molecules/SearchBar";
import SearchHeader from "../components/atoms/SearchHeader";
import { useSearch } from "../context/SearchContext";
import "../index.css";
import CustomAlert from "../components/atoms/CustomAlert";
import { PoweredByAIFindr } from "../assets/PoweredByAIFindr";
import { useTranslation } from "react-i18next";
import UserFeedback from "../components/organisms/UserFeedback.tsx";

const ResponsePage: React.FC = () => {
  const { t } = useTranslation();
  const {
    handleSearch,
    currentSearchTerm,
    textResponse,
    isLoading,
    sources,
    duration,
    requestId,
    contextId,
  } = useSearch();

  const [selectedFeedback, setSelectedFeedback] = useState<
    "angry" | "neutral" | "happy" | null
  >(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const handleRefreshClick = () => {
    setIsFeedbackSent(false);
    handleSearch(currentSearchTerm, "refresh").then();
  };
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const bottomDivRef = useRef<HTMLDivElement>(null);
  const [scrollableHeight, setScrollableHeight] = useState(0);

  const handleFeedbackClick = (feedback: "angry" | "neutral" | "happy") => {
    setSelectedFeedback(feedback);
    setIsFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setIsFeedbackOpen(false);
  };

  const handleFeedbackChange = (
    feedback: "angry" | "neutral" | "happy" | null,
  ) => {
    setSelectedFeedback(feedback);
  };

  const calculateHeight = () => {
    if (scrollableDivRef.current && bottomDivRef.current) {
      const bottomDivHeight = bottomDivRef.current.offsetHeight;
      const availableHeight = window.innerHeight - bottomDivHeight - 20;
      setScrollableHeight(availableHeight);
    }
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <div
      style={{ backgroundColor: "#FFFFFF" }}
      className="flex flex-col w-full min-h-screen items-center"
    >
      <div
        ref={scrollableDivRef}
        className={`flex-grow relative w-full overflow-y-auto scrollbar-hide justify-center items-center flex mt-5 ${
          isLoading || isFeedbackSent
            ? "max-h-[65vh] lg:max-h-[70vh] xl:max-h-[75vh] 2xl:max-h-[75vh] "
            : "max-h-[48vh] lg:max-h-[58vh] xl:max-h-[65vh] 2xl:max-h-[65vh]"
        }`}
        style={{ height: scrollableHeight ? `${scrollableHeight}px` : "auto" }}
      >
        <div
          className={`flex-grow flex flex-col max-w-2xl px-6 justify-start my-16 space-y-8 h-full ${
            !isFeedbackOpen ? "z-40" : ""
          }`}
        >
          <div className="flex flex-col space-y-4">
            <SearchHeader>{currentSearchTerm}</SearchHeader>
          </div>
          <ResponseContent
            textResponse={textResponse}
            sources={sources}
            isLoading={isLoading}
            handleRefreshClick={handleRefreshClick}
            query={currentSearchTerm}
            requestId={requestId}
            contextId={contextId}
          />

          {isLoading && (
            <div className="flex justify-center items-center mx-auto max-w-[4rem] lg:my-48">
              <img src="simpleSpinner.gif" alt="loading" />
            </div>
          )}
        </div>
      </div>

      <div
        ref={bottomDivRef}
        className="fixed bottom-0 w-full z-30 flex flex-col items-center"
      >
        {!isLoading && !isFeedbackSent && (
          <div className="w-full">
            <div className="flex flex-col items-center bg-white p-5 space-y-2">
              <h3 className="text-REPEOPLE-dark-blue text-[16px]">
                {t("translation.feedback-title")}
              </h3>
              <div className="flex space-x-4">
                <div
                  onClick={() => handleFeedbackClick("angry")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="angry"
                    className={
                      selectedFeedback === "angry" ? "selected" : "not-selected"
                    }
                  >
                    😢
                  </span>
                </div>
                <div
                  onClick={() => handleFeedbackClick("neutral")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="neutral"
                    className={
                      selectedFeedback === "neutral"
                        ? "selected"
                        : "not-selected"
                    }
                  >
                    🙂
                  </span>
                </div>
                <div
                  onClick={() => handleFeedbackClick("happy")}
                  className="cursor-pointer"
                >
                  <span
                    role="img"
                    aria-label="happy"
                    className={
                      selectedFeedback === "happy" ? "selected" : "not-selected"
                    }
                  >
                    😍
                  </span>
                </div>
              </div>
              <div className="w-full flex justify-center ">
                {isFeedbackSent && (
                  <CustomAlert message="Entendido, ¡Muchas gracias por tu feedback!" />
                )}
              </div>
            </div>
          </div>
        )}

        <UserFeedback
          duration={duration}
          textResponse={textResponse}
          sources={sources}
          requestId={requestId}
          contextId={contextId}
          query={currentSearchTerm}
          isOpen={isFeedbackOpen}
          onClose={handleFeedbackClose}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
          onFeedbackChange={handleFeedbackChange}
          isFeedbackSent={isFeedbackSent}
          setIsFeedbackSent={setIsFeedbackSent}
        />

        <div className="w-full">
          <div className="max-w-[1200px] pt-3 pb-8 px-6 bg-white min-[1200px]:rounded-[24px] mx-auto">
            <div className="max-w-[604px] mx-auto">
              <SearchBar
                isInternalSearch={true}
                isLoading={isLoading}
                setFeedbackSent={setIsFeedbackSent}
              />
            </div>
            <footer className="flex justify-center items-center w-full py-4 text-white">
              <PoweredByAIFindr width="124" height="14" />
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsePage;
