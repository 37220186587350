import React from "react";
import "../../index.css";
interface WaveAnimationProps {
  waveHeights: number[];
}

const WaveAnimation: React.FC<WaveAnimationProps> = ({ waveHeights }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
      <div className="flex">
        {waveHeights.map((scale, i) => (
          <span
            key={i}
            className="barWave"
            style={{
              animationDelay: `${(i * 0.1).toFixed(2)}s`,
              ["--scaleMax" as keyof React.CSSProperties]: scale,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default WaveAnimation;
