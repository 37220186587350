import React from "react";

interface MicrophoneButtonProps {
  isListening: boolean;
  toggleListening: () => void;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({
  isListening,
  toggleListening,
}) => {
  return (
    <button
      type="button"
      onClick={toggleListening}
      className="focus:outline-none"
    >
      {isListening ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5 text-red-500 animate-pulse"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 6.75h10.5v10.5H6.75z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5 text-gray-600 hover:text-black transition-colors"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 1.5c1.657 0 3 1.343 3 3v5.497a3 3 0 11-6 0V4.5c0-1.657 1.343-3 3-3z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 10.497c0 3.59-2.91 6.501-6.5 6.501s-6.5-2.91-6.5-6.501"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.498v1.502a3 3 0 01-6 0v-1.502"
          />
        </svg>
      )}
    </button>
  );
};

export default MicrophoneButton;
