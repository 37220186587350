interface RoundedButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  customClass?: string;
}
export function RoundedButton({
  label,
  onClick,
  disabled = false,
  customClass = "16px",
}: RoundedButtonProps) {
  return (
    <button
      style={{ boxShadow: "none" }}
      className={`${customClass}  w-full px-3 rounded-full transition-colors duration-300 ${
        disabled
          ? "bg-REPEOPLE-light-gray text-REPEOPLE-dark-gray opacity-50"
          : "bg-REPEOPLE-orange text-white"
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

export default RoundedButton;
