import React from "react";
import CheckMarkIcon from "../../assets/CheckMarkIcon";

interface CustomAlertProps {
  message: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message }) => (
  <div className="text-REPEOPLE-dark-gray flex items-end justify-center space-x-2 text-[12px] py-2.5 px-3 rounded-lg font-[400] bg-REPEOPLE-light-green">
    <CheckMarkIcon />
    <span>{message}</span>
  </div>
);

export default CustomAlert;
