import React, { useState, useEffect, useRef, useCallback } from "react";
import RoundedButton from "../atoms/RoundedButton";
import { InputText } from "primereact/inputtext";
import { submitFeedbackEllmental } from "../../services/FeedbackService";
import { Source } from "../../services/PipelineService";
import {
  sendUserFeedback,
  sendUserFeedbackSelected,
} from "../../utils/analyticsEvents";
import { getBrowserInfo } from "../../utils/browserInfo";
import { AngryFaceIcon } from "../../assets/AngryFaceIcon";
import { HappyFaceIcon } from "../../assets/HappyFaceIcon";
import { NeutralFaceIcon } from "../../assets/NeutralFaceIcon";
import CustomChecklistItem from "../atoms/CustomChecklistItem";

interface UserFeedbackProps {
  duration: number;
  textResponse: string;
  sources: Source[];
  requestId: string;
  contextId: string;
  query: string;
  isOpen: boolean;
  onClose: () => void;
  selectedFeedback: "angry" | "neutral" | "happy" | null;
  setSelectedFeedback: React.Dispatch<
    React.SetStateAction<"angry" | "neutral" | "happy" | null>
  >;
  onFeedbackChange: (feedback: "angry" | "neutral" | "happy" | null) => void;
  setIsFeedbackSent: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedbackSent: boolean;
}

const UserFeedback: React.FC<UserFeedbackProps> = ({
  duration,
  textResponse,
  sources,
  requestId,
  contextId,
  query,
  isOpen,
  onClose,
  selectedFeedback,
  setSelectedFeedback,
  onFeedbackChange,
  setIsFeedbackSent,
  isFeedbackSent,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const browserInfo = getBrowserInfo();
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closePanel = useCallback(() => {
    setSelectedFeedback(null);
    setSelectedOptions([]);
    setFeedbackText("");
    setIsFeedbackSent(true);
    onClose();
  }, [setSelectedFeedback, setIsFeedbackSent, onClose]);

  useEffect(() => {
    const handleClickOutside = async (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        const userMetadata = {
          selectedOptions: ["EMOJI"],
          feedbackCategory: selectedFeedback,
          duration,
          textResponse,
          sources: JSON.stringify(sources),
          numberOfSources: sources.length,
          datetime: new Date().toISOString(),
          browserInfo: JSON.stringify(browserInfo),
          comment: "",
          requestId,
          query: query,
        };
        await submitFeedbackEllmental("", userMetadata, requestId);
        sendUserFeedbackSelected(
          requestId,
          query,
          selectedFeedback,
          contextId,
          false,
        );
        closePanel();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    browserInfo,
    closePanel,
    contextId,
    duration,
    isOpen,
    onClose,
    query,
    requestId,
    selectedFeedback,
    sources,
    textResponse,
  ]);

  const handleFeedbackClick = (feedback: "angry" | "neutral" | "happy") => {
    onFeedbackChange(feedback);
    setSelectedOptions([]);
    setIsFeedbackSent(false);
  };

  const handleRecommendationToggle = (label: string) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((item) => item !== label)
        : [...prev, label],
    );
  };

  const isSubmitDisabled =
    !selectedFeedback ||
    (selectedOptions.length === 0 && feedbackText.trim() === "");

  const recommendationLabels = {
    angry: [
      "Información incorrecta",
      "No era lo que buscaba",
      "Falta información relevante",
      "Es difícil de entender",
      "Falta ser más específico",
    ],
    neutral: [
      "Información incorrecta",
      "No era lo que buscaba",
      "Falta información relevante",
      "Es difícil de entender",
      "Falta ser más específico",
    ],
    happy: [
      "Información precisa y completa",
      "Fácil de entender",
      "Útil y aplicable",
      "Respuesta rápida",
      "Buena estructura",
    ],
  };

  const handleSubmitFeedback = async () => {
    const userMetadata = {
      selectedOptions,
      feedbackCategory: selectedFeedback,
      duration,
      textResponse,
      sources: JSON.stringify(sources),
      numberOfSources: sources.length,
      datetime: new Date().toISOString(),
      browserInfo: JSON.stringify(browserInfo),
      comment: feedbackText,
      requestId,
      query,
    };

    await submitFeedbackEllmental(feedbackText, userMetadata, requestId);

    sendUserFeedback(
      requestId,
      query,
      selectedFeedback,
      selectedOptions,
      feedbackText,
      contextId,
      selectedOptions.length > 0 || feedbackText.trim() !== "",
    );
    closePanel();
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ${
          isOpen ? "bg-opacity-50 z-50" : "bg-opacity-0 -z-10"
        }`}
        onClick={closePanel}
      ></div>

      <div
        className={`fixed bg-white shadow-lg duration-300 ease-in-out z-[100]
          ${
            isSmallScreen
              ? "inset-x-0 bottom-0 h-[90%] rounded-t-3xl"
              : "inset-y-0 right-0 w-[440px]"
          } 
          ${
            isOpen
              ? isSmallScreen
                ? "translate-y-0"
                : "translate-x-0"
              : isSmallScreen
              ? "translate-y-full"
              : "translate-x-full"
          }`}
        style={{ visibility: isOpen ? "visible" : "hidden" }}
        ref={panelRef}
      >
        <div className="p-4 h-full overflow-y-auto text-center pt-4 space-y-8">
          <button
            onClick={closePanel}
            className="absolute top-4 right-4 text-gray-500"
          >
            <i className="pi pi-times text-REPEOPLE-orange" />
          </button>
          <div className="space-y-4">
            <h2 className="text-xl font-bold mb-4 text-REPEOPLE-dark-blue">
              {selectedFeedback === "angry" || selectedFeedback === "neutral"
                ? "Por favor cuéntanos, ¿qué fue lo que no te gustó?"
                : "Por favor cuéntanos, ¿qué fue lo que te gustó?"}
            </h2>
            <div className="flex justify-around mb-4 max-w-[200px] mx-auto">
              <div onClick={() => handleFeedbackClick("angry")}>
                <AngryFaceIcon selected={selectedFeedback === "angry"} />
              </div>
              <div onClick={() => handleFeedbackClick("neutral")}>
                <NeutralFaceIcon selected={selectedFeedback === "neutral"} />
              </div>
              <div onClick={() => handleFeedbackClick("happy")}>
                <HappyFaceIcon selected={selectedFeedback === "happy"} />
              </div>
            </div>
          </div>

          {selectedFeedback && !isFeedbackSent && (
            <div className="space-y-5">
              <h3 className="text-xl font-bold mb-4 text-REPEOPLE-dark-blue">
                {selectedFeedback === "happy"
                  ? "¿Qué fue lo que te gustó?"
                  : "¿Qué fue lo que no te gustó?"}
              </h3>
              <div className="flex flex-wrap gap-2 mb-4">
                {recommendationLabels[selectedFeedback].map((label) => (
                  <CustomChecklistItem
                    key={label}
                    label={label}
                    isSelected={selectedOptions.includes(label)}
                    onClick={() => handleRecommendationToggle(label)}
                  />
                ))}
              </div>
              <div className="mb-4">
                <InputText
                  id="feedback-input"
                  placeholder="(Opcional) Escribe otros comentarios aquí"
                  value={feedbackText}
                  onChange={(e) =>
                    setFeedbackText(e.target.value.slice(0, 200))
                  }
                  maxLength={200}
                  className="w-full py-2 mb-1 border mx-1 border-black"
                />
                <div className="border-b mb-1 mx-3"></div>
                <div className="text-right text-sm mr-3 text-gray-500">
                  {feedbackText.length}/200
                </div>
              </div>
              <div className="flex justify-center pb-12 max-w-[340px] mx-auto">
                <RoundedButton
                  label="Enviar"
                  onClick={handleSubmitFeedback}
                  disabled={isSubmitDisabled}
                  customClass="h-[40px]"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserFeedback;
